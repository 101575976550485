import { createSubmission } from '@wix/ambassador-forms-v4-submission/http';
import { Plan } from '@wix/ambassador-pricing-plans-v3-plan/types';
import type { FormValues } from '@wix/form-viewer';
import { initFormController } from '@wix/form-viewer/controller';
import type { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { WIX_FORMS_NAMESPACE } from '../../../constants/wix-forms';

export class FormsService {
  constructor(private readonly flowAPI: ControllerFlowAPI) {}

  maybeInitFormController(plan: Plan) {
    if (plan.formId) {
      return initFormController(this.flowAPI, { formId: plan.formId, namespace: WIX_FORMS_NAMESPACE });
    }
  }

  async maybeSubmitForm(formId: Plan['formId'], values?: FormValues | null): Promise<string | null> {
    if (!formId || !values) {
      return null;
    }
    try {
      const result = await this.flowAPI.httpClient.request(
        createSubmission({
          submission: {
            formId,
            submissions: values,
          },
        }),
      );
      return result.data.submission?.id ?? null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
}
