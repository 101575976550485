import { PlanOptions, Plan } from '@wix/ambassador-pricing-plans-v3-plan/types';
import { PlanCustomizationPublicData } from '@wix/pricing-plans-router-utils';
import type { CreateControllerFn, ControllerParams, ControllerFlowAPI, IWixAPI, FlowAPI } from '@wix/yoshi-flow-editor';
import { plansFixtureTranslated } from '../../fixtures';
import { SettingsApi } from '../../services/settings';
import { resolveLocale } from '../../utils';
import { planV2ToDemoPlanV3 } from './mappers/plan-v2-to-demo-plan-v3';
import { EcomService } from './services/ecom';
import { FormsService } from './services/forms';
import { RootProductPageProps } from './Widget';
import { PlanCustomization } from './Widget/PlanCustomizationWidget';

const createController: CreateControllerFn = async ({ flowAPI }: ControllerParams) => {
  return {
    async pageReady() {
      const wixCodeApi = flowAPI.controllerConfig.wixCodeApi;
      const isLoggedIn = wixCodeApi.user.currentUser.loggedIn;
      const setProps: (props: Partial<RootProductPageProps>) => void = flowAPI.controllerConfig.setProps;
      const formsService = new FormsService(flowAPI);
      const ecomService = new EcomService(flowAPI);

      setProps({
        status: 'PAGE_LOADING',
        locale: resolveLocale(wixCodeApi),
        proceedToPayment,

        // @ts-expect-error Undocumented viewer prop, which adds "height: auto" to root TPA section
        fitToContentHeight: true,
      });

      const routerData = await getProductPageData(flowAPI);
      const {
        plan,
        customizationData: { guestCheckoutEnabled },
      } = routerData;

      // TODO: Perhaps have a separate prop for form loading status
      await formsService.maybeInitFormController(plan);
      if (!isLoggedIn) {
        wixCodeApi.user.onLogin(async () => {
          if (wixCodeApi.user.currentUser.loggedIn) {
            setProps({ shouldRequestLogin: false });
          }
        });
      }
      setProps({ status: 'PAGE_LOADED', plan, shouldRequestLogin: guestCheckoutEnabled ? false : !isLoggedIn });

      async function proceedToPayment({ formValues, startDate }: PlanCustomization) {
        const canProceed = await verifyUserLoginStatus(wixCodeApi, routerData);
        if (!canProceed) {
          return;
        }

        try {
          setProps({ status: 'CHECKOUT_LOADING' });
          const submissionId = await formsService.maybeSubmitForm(plan.formId, formValues);

          const options = getPlanOptions({ plan, submissionId, startDate });
          const checkout = await ecomService.createCheckout(plan.id!, options);

          await ecomService.navigateToCheckout(checkout.id!);
        } catch (e) {
          setProps({ status: 'ERROR' });
        }
      }
    },
    async updateConfig() {},
  };
};

export default createController;

async function verifyUserLoginStatus(wixCodeApi: IWixAPI, pageData: PlanCustomizationPublicData): Promise<boolean> {
  if (wixCodeApi.user.currentUser.loggedIn || pageData.customizationData.guestCheckoutEnabled) {
    return true;
  }

  const user = await wixCodeApi.user.promptLogin({ mode: 'login', modal: true });
  return user.loggedIn;
}

async function getProductPageData(flowAPI: ControllerFlowAPI): Promise<PlanCustomizationPublicData> {
  const routerData = flowAPI.controllerConfig.wixCodeApi.window.getRouterPublicData<PlanCustomizationPublicData>();
  if (!routerData) {
    if (flowAPI.environment.isEditor) {
      const settingsApi = new SettingsApi(flowAPI.httpClient);
      const demoPlan = getDemoPlanV3(flowAPI.translations.t);
      const settings = await settingsApi.getSettings().catch(() => null);
      return {
        plan: demoPlan,
        customizationData: {
          integrationData: {},
          guestCheckoutEnabled: Boolean(settings?.guestCheckout?.enableGuestCheckout),
          planId: demoPlan.id!,
        },
      };
    }

    // TODO: Add fallback client calls to get the data
    throw new Error('Router data is missing');
  }

  return routerData;
}

function getPlanOptions(data: { plan: Plan; submissionId?: string | null; startDate?: Date | null }): PlanOptions {
  const { plan, submissionId, startDate } = data;
  const pricingVariantId = plan.pricingVariants![0].id!;
  const options: PlanOptions = { pricingVariantId };

  if (submissionId) {
    options.submissionId = submissionId;
  }

  if (isStartDateValid(startDate)) {
    options.startDate = startDate;
  }

  return options;
}

function isStartDateValid(date?: Date | null): boolean {
  if (!date) {
    return false;
  }

  const today = new Date();
  if (date < today) {
    return false;
  }

  if (date.getDate() === today.getDate()) {
    return false;
  }

  return true;
}

function getDemoPlanV3(t: FlowAPI['translations']['t']): Plan {
  const [planV2] = plansFixtureTranslated(t);
  return planV2ToDemoPlanV3(planV2);
}
